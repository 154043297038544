import { Skeleton } from "antd"
import CPInput from "components/CP/CPInput"
import ConnectionListRow from "components/Messaging/ConnectionListRow"
import { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch, useSelector } from "react-redux"
import {
  messageState,
  setListExpand,
  setNewChatOpened,
  setOpenedChatDetails,
} from "redux/slices/messageSlice"
import { profileState } from "redux/slices/profileReducer"
import { buildSearchQuery } from "utils/global"
import useFetch from "utils/useFetch"

const MyAvatar = ({ className }) => {
  const { userInfo } = useSelector(profileState)

  return (
    <div
      className={`w-8 h-8 rounded-full border border-solid border-gray-600 bg-cover cursor-pointer bg-center ${className}`}
      style={{
        backgroundImage: `url(${
          userInfo?.icon || "/assets/onBoarding/profile_avatar.jpg"
        })`,
      }}
    />
  )
}

const Loading = () => {
  return (
    <div className="px-4">
      <Skeleton active paragraph={{ rows: 1 }} className="small-skeleton" />
    </div>
  )
}

const RenderHeader = () => {
  const dispatch = useDispatch()
  const { isListExpand, isNewChatOpened } = useSelector(messageState)

  return (
    <div className="flex items-center flex-shrink-0 p-3">
      <div
        className="flex-1 flex items-center cursor-pointer"
        onClick={() => dispatch(setListExpand(!isListExpand))}
      >
        <MyAvatar className="flex-shrink-0" />
        <div className="font-medium ml-3.5 flex-1">Messaging</div>
      </div>
      <span
        className="icon-ic_create_24px border-b-2 pb-1 border-gray-300 cursor-pointer hidden sm:block"
        onClick={() => dispatch(setNewChatOpened(!isNewChatOpened))}
      />
      {isListExpand && (
        <span
          className="icon-ic_arrow_drop_down_24px f-6 block sm:hidden"
          onClick={() => dispatch(setListExpand(!isListExpand))}
        />
      )}
    </div>
  )
}

const RenderList = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchFilters, setSearchFilters] = useState(null)

  let url = `/api/message/rooms/?page=${page}`
  if (searchFilters?.search_q) {
    url = `/api/network/connection/?page=${page}${buildSearchQuery(
      searchFilters
    )}`
  }

  const [loadingData, fetchedData, { error }] = useFetch(url)
  const [fetchPagination, setFetchPagination] = useState(null)
  const isLastPage =
    fetchedData?.meta?.current_page >= fetchedData?.meta?.last_page

  const handleFilter = params => {
    setSearchFilters(params)
    setFetchPagination(null)
    setPage(1)
  }

  const setFilter = params => {
    const final = { ...searchFilters, ...params }
    handleFilter(final)
  }

  const removeFilter = nameArray => {
    const final = { ...searchFilters }
    nameArray?.forEach(element => {
      delete final[element]
    })
    handleFilter(final)
  }

  useEffect(() => {
    if (fetchedData?.data?.length > 0) {
      if (page === 1) {
        setFetchPagination([...fetchedData.data])
      } else {
        setFetchPagination([...(fetchPagination || []), ...fetchedData.data])
      }
    }
  }, [fetchedData])

  return (
    <div style={{ height: 315 }} className="flex flex-col">
      <div className="px-3 pb-2">
        <CPInput
          placeholder="Search"
          prefix={<span className="icon-ic_search_24px" />}
          allowClear
          onChange={e => {
            e?.target?.value
              ? setFilter({ search_q: e?.target?.value })
              : removeFilter(["search_q"])
          }}
        />
      </div>

      <div className="flex-1 overflow-y-auto overflow-x-hidden">
        <InfiniteScroll
          pageStart={1}
          useWindow={false}
          initialLoad={false}
          hasMore={!isLastPage}
          loadMore={() =>
            !isLastPage && setPage(fetchedData?.meta?.current_page + 1)
          }
        >
          {searchFilters?.search_q ? (
            <>
              {fetchPagination?.length > 0 &&
                fetchPagination?.map((item, index) => (
                  <ConnectionListRow
                    key={index}
                    img={item?.profile_photo}
                    newMessages={item?.new_messages}
                    title={`${item?.first_name} ${item?.last_name}`}
                    onClick={() => dispatch(setOpenedChatDetails(item))}
                  />
                ))}

              {!loadingData && !fetchPagination?.length && (
                <div className="my-4 text-center text-gray-500 f-12">
                  No result found!
                </div>
              )}
            </>
          ) : (
            <>
              {fetchPagination?.length > 0 &&
                fetchPagination?.map((item, index) => (
                  <ConnectionListRow
                    key={index}
                    newMessages={Number(item?.new_messages)}
                    title={item?.receiver_user_full_name}
                    subtitle={`${item?.sender_of_latest_message}: ${item?.latest_message}`}
                    img={item?.receiver_user_icon}
                    onClick={() =>
                      dispatch(
                        setOpenedChatDetails({
                          ...item,
                          name: item?.receiver_user_full_name,
                          user_id: item?.receiver_user_id || item?.user_id,
                          room_id: item?.room_id,
                        })
                      )
                    }
                  />
                ))}

              {!loadingData && !fetchPagination?.length && (
                <div className="my-4 text-center text-gray-500 f-12">
                  You have no connection!
                </div>
              )}
            </>
          )}

          {!error && loadingData && <Loading />}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default function ConnectionList() {
  const messageInfo = useSelector(messageState)
  const isListExpand = messageInfo?.isListExpand

  return (
    <div style={isListExpand ? { height: 375 } : { height: 56 }}>
      <RenderHeader />

      {isListExpand && <RenderList />}
    </div>
  )
}
