import { Skeleton } from "antd"
import NotificationMessageItem from "components/Notifications/NotificationMessageItem"
import { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useSelector } from "react-redux"
import { profileState } from "redux/slices/profileReducer"
import apiService from "services/apiService"
import { notifyToast } from "services/notifyToast"

export default function NotificationMessages(Props) {
  const { userInfo } = useSelector(profileState)

  const [page, setPage] = useState(1)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState(null)
  const isLastPage = pagination?.current_page >= pagination?.last_page

  const fetchData = async () => {
    try {
      const { data } = await apiService.get(
        `/api/profile/get-notification-inbox?page=${page}`
      )

      setPagination(data?.meta)

      if (page === 1) {
        setItems(data?.data)
      } else {
        setItems([...items, ...data.data])
      }
    } catch (e) {
      notifyToast(e)
    }

    setLoading(false)
  }

  /**
   * fetch data
   */
  useEffect(() => {
    setLoading(true)

    fetchData()
  }, [userInfo, page])

  return (
    <div {...Props}>
      {loading && items?.length === 0 && (
        <Skeleton className="small-skeleton" active paragraph={{ rows: 1 }} />
      )}

      {items?.length !== 0 ? (
        <InfiniteScroll
          pageStart={0}
          useWindow={false}
          initialLoad={false}
          hasMore={!isLastPage}
          loadMore={() => !isLastPage && setPage(pagination?.current_page + 1)}
          loader={
            <Skeleton
              key={0}
              active
              className="small-skeleton"
              paragraph={{ rows: 1 }}
            />
          }
        >
          {items.map((item, index) => (
            <NotificationMessageItem key={index} item={item} />
          ))}
        </InfiniteScroll>
      ) : (
        !loading && (
          <div className="pb-2 text-gray-500 f-13">No new message.</div>
        )
      )}
    </div>
  )
}
