import { useCallback, useEffect, useState } from "react"
import { getItem } from "./storage"

const useFetch = (
  url,
  { skip = false, hideError = false, initialData = null } = {}
) => {
  const [data, setData] = useState(initialData)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(async () => {
    try {
      setError(null)
      setLoading(true)

      const token = await getItem("access_token")
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_BASE_URL}${url}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const json = await response.json()

      setData(json)
    } catch (e) {
      console.error(e)
      setData(null)
    } finally {
      setLoading(false)
    }
  }, [url, skip, hideError])

  const refresh = useCallback(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!url || skip || loading) {
      return
    }

    fetchData()
  }, [fetchData, url, skip])

  return [loading, data, { error, refresh }]
}

export default useFetch
