import { Layout } from "antd"
import Footer from "components/Footer"
import Header from "components/Header/Header"
import LoadCookiePermission from "components/LoadCookiePermission"
import Messaging from "components/Messaging"
import FirebaseApp from "components/WebPush"
import { useRouter } from "next/router"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { profileState } from "redux/slices/profileReducer"

const footerConditions = [
  "details",
  "companies",
  "individuals",
  "community",
  "communities",
  "privacy",
  "cookies",
]

export default function Index({ children }) {
  const router = useRouter()

  const { userInfo } = useSelector(profileState)
  const isHome = router?.pathname === "/"

  const shouldToggleFooter = useMemo(
    () => footerConditions?.some(item => router?.pathname?.includes(item)),
    [router]
  )

  return (
    <>
      <Header isHome={!userInfo && isHome} />

      <Layout style={{ paddingTop: 56 }} className="fadeIn">
        {children}
      </Layout>

      <FirebaseApp />
      <LoadCookiePermission />

      {userInfo && <Messaging />}
      {!isHome && !shouldToggleFooter && <Footer />}
    </>
  )
}
