import CPInput from "components/CP/CPInput"
import ChatDetails from "components/Messaging/ChatDetails"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import Link from "next/link"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { messageState, setOpenedChatDetails } from "redux/slices/messageSlice"
import apiService from "services/apiService"
import { notifyToast } from "services/notifyToast"

const RenderHeader = ({ onDispatch, openedChatDetails }) => {
  return (
    <div className="flex items-center flex-shrink-0 p-3">
      <span
        className="icon-ic_arrow_back_24px cursor-pointer"
        onClick={() => onDispatch(setOpenedChatDetails(null))}
      />
      <div className="flex-1 flex items-center">
        <Link
          prefetch={false}
          passHref
          href={`/profile/${
            openedChatDetails?.username || openedChatDetails?.receiver_username
          }`}
          className="font-medium ml-3.5 flex-1 truncate max-w-220 text-black"
        >
          {openedChatDetails?.name ||
            openedChatDetails?.full_name ||
            openedChatDetails?.first_name ||
            "Message"}
        </Link>
      </div>
    </div>
  )
}

const sendMessage = async ({
  userId = null,
  roomId = null,
  message = "",
  onDispatch,
  openedChatDetails,
}) => {
  try {
    const res = await apiService.post("/api/message/store", {
      message,
      room_id: roomId,
      receiver_user_id: userId,
    })
    if (res.status === 200) {
      if (res.data?.data?.room_id && !openedChatDetails?.room_id) {
        onDispatch(
          setOpenedChatDetails({
            ...openedChatDetails,
            room_id: res.data?.data?.room_id,
          })
        )
      }

      return res.data?.data
    }
  } catch (e) {
    notifyToast(e)
  }
}

const RenderSendMsgForm = ({ onDispatch, onNewMessage, openedChatDetails }) => {
  const { checkSubscription } = useSubscriptionContext()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")

  const handleSendMsg = async event => {
    event?.preventDefault()
    if (!message) {
      return
    }

    try {
      setLoading(true)
      const res = await sendMessage({
        onDispatch,
        openedChatDetails,
        message: message || message,
        userId: openedChatDetails?.user_id || openedChatDetails?.id || null,
        roomId: openedChatDetails?.room_id || null,
      })

      setMessage("")
      if (onNewMessage) {
        onNewMessage(res)
      }
    } catch (e) {
      checkSubscription(e)
    }

    setLoading(false)
  }

  return (
    <div className="px-5 my-4">
      <CPInput
        disabled={loading}
        placeholder="Write a message..."
        suffix={
          <span
            className={`icon-ic_send_24px ml-2 cursor-pointer ${
              message ? "text-gray-500" : "text-gray-500"
            }`}
            onClick={handleSendMsg}
          />
        }
        allowClear
        onPressEnter={handleSendMsg}
        onChange={e => {
          setMessage(e?.target?.value)
        }}
        value={message}
      />
    </div>
  )
}

export default function ChatDetailsContainer() {
  const dispatch = useDispatch()
  const [newData, setNewData] = useState(null)
  const { openedChatDetails } = useSelector(messageState)

  return (
    <div style={{ height: 375 }} className="flex flex-col">
      <RenderHeader
        onDispatch={dispatch}
        openedChatDetails={openedChatDetails}
      />

      {openedChatDetails?.room_id ? (
        <ChatDetails newData={newData} />
      ) : (
        <div className="flex-1 px-4 text-center f-12">
          No conversation here!
        </div>
      )}

      <RenderSendMsgForm
        onDispatch={dispatch}
        openedChatDetails={openedChatDetails}
        onNewMessage={event => {
          if (openedChatDetails?.room_id) {
            setNewData(event)
          }
        }}
      />
    </div>
  )
}
