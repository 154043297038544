import ChatDetailsContainer from "components/Messaging/ChatDetailsContainer"
import ConnectionList from "components/Messaging/ConnectionList"
import NewMessageList from "components/Messaging/NewMessageList"
import { useSelector } from "react-redux"
import { messageState } from "redux/slices/messageSlice"

function Wrapper({ children }) {
  const messageInfo = useSelector(messageState)
  const isListExpand = messageInfo?.isListExpand

  return (
    <div
      className={`fixed bottom-0 right-0 sm:right-3 z-50 bg-white text-black ${
        isListExpand
          ? "w-full sm:w-72 overflow-y-auto overflow-x-hidden message-wrapper"
          : "w-48 sm:w-72 overflow-hidden sm:rounded-none"
      }`}
      style={{ boxShadow: "0 2px 7px 0 rgba(0, 0, 0, 0.3)" }}
    >
      {children || ""}
    </div>
  )
}

export default function Messaging() {
  const { isNewChatOpened, openedChatDetails } = useSelector(messageState)

  if (openedChatDetails) {
    return (
      <Wrapper>
        <ChatDetailsContainer />
      </Wrapper>
    )
  }

  if (isNewChatOpened) {
    return (
      <Wrapper>
        <NewMessageList />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <ConnectionList />
    </Wrapper>
  )
}
