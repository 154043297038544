import { Col, Row } from "antd"
import CPInputWithLabelHOC from "components/CP/CPInputWithLabelHOC"
import CPSelect from "components/CP/CPSelect"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { countryReduxState, getCountriesList } from "redux/slices/countrySlice"
import useFetch from "utils/useFetch"

function RenderCountry({ onChange, ...params }) {
  const countryList = useSelector(countryReduxState)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!countryList?.country) {
      dispatch(getCountriesList())
    }
  }, [])

  return (
    <CPSelect
      {...params}
      loading={countryList?.loading}
      disabled={countryList?.loading}
      options={countryList?.country?.map(item => ({
        label: item?.name,
        value: item?.id,
      }))}
      onChange={(d, e) => {
        if (onChange) {
          onChange(d, e)
        }
      }}
    />
  )
}

function CountryAndCitySelector({
  rules: formRules,
  column,
  onChange,
  initialData,
  labelconfig = {},
}) {
  const { control, errors, watch, setValue, register } = useForm({
    defaultValues: { ...initialData },
  })

  register("country")
  register("city")

  const formValues = watch()
  const [selectedCountry, setSelectedCountry] = useState(null)

  const [loading, data] = useFetch(
    `/api/list/get-cities-by-country?country_id=${selectedCountry}`,
    {
      skip: !selectedCountry,
    }
  )

  useEffect(() => {
    if (!initialData?.country_id) {
      setValue("city", undefined)
      setValue("city_id", undefined)
      setValue("country", undefined)
      setValue("country_id", undefined)
    } else if (initialData?.country_id && initialData?.city_id) {
      setValue("city_id", initialData?.city_id)
      setValue("country_id", initialData?.country_id)
      setSelectedCountry(initialData?.country_id)
    }
  }, [initialData])

  return (
    <Row gutter={[15, 15]}>
      <Col xs={24} sm={column ? 24 : 12}>
        <Controller
          name="country_id"
          control={control}
          rules={formRules?.country_id}
          render={({ field: { value } }) => (
            <RenderCountry
              value={value}
              onChange={(d, e) => {
                setValue("country_id", d)
                setValue("country", e?.label)
                setValue("city_id", undefined)
                setValue("city", undefined)

                setSelectedCountry(d)

                if (onChange) {
                  onChange({
                    country: e?.label,
                    country_id: d,
                    city: null,
                    city_id: null,
                  })
                }
              }}
              showSearch
              allowClear
              optionFilterProp="label"
              labelconfig={{
                autoComplete: "off",
                required: !!formRules?.country_id?.required,
                label: !labelconfig?.label ? "Country" : undefined,
                error: errors?.country_id?.message || null,
              }}
            />
          )}
        />
      </Col>

      <Col xs={24} sm={column ? 24 : 12}>
        <Controller
          name="city_id"
          control={control}
          rules={formRules?.city_id}
          render={({ field: { value } }) => (
            <CPSelect
              value={value}
              onChange={(d, e) => {
                setValue("city_id", d)
                setValue("city", e?.label)
                if (onChange) {
                  onChange({
                    country: formValues?.country,
                    country_id: formValues?.country_id,
                    city: e?.label,
                    city_id: d,
                  })
                }
              }}
              showSearch
              allowClear
              loading={loading}
              optionFilterProp="label"
              options={data?.data?.map(item => ({
                label: item?.name,
                value: item?.id,
              }))}
              disabled={!formValues?.country_id || loading}
              labelconfig={{
                autoComplete: "off",
                required: formRules?.city_id?.required,
                error: errors?.city_id?.message || null,
                label: !labelconfig?.label ? "City" : undefined,
              }}
            />
          )}
        />
      </Col>
    </Row>
  )
}

export default CPInputWithLabelHOC(CountryAndCitySelector)
