import { Button, Col, Divider, Drawer, Menu, notification, Row } from "antd"
import ModalAddCommunity from "components/Community/addCommunity/AddModal"
import ModalAddCompany from "components/Company/ModalAddCompany"
import CPButton from "components/CP/CPButton"
import styles from "components/Header/Header.module.scss"
import ProfileMenu from "components/Header/ProfileMenu"
import UserBox from "components/Header/UserBox"
import Link from "next/link"
import { useRouter } from "next/router"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { profileState } from "redux/slices/profileReducer"
import { getItem, removeItem, setItem } from "utils/storage"

const Header = ({ isHome }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { userInfo } = useSelector(profileState)
  const [addCompany, setAddCompany] = useState(false)
  const [addCommunity, setAddCommunity] = useState(false)
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const items = [
    { href: "/individuals", label: "Individuals" },
    { href: "/companies", label: "Companies" },
    { href: "/communities", label: "Communities" },
    { href: "https://blog.birdflocks.com/", label: "Blog", target: "_blank" },
  ]
  const otherItems = [
    { href: "/privacy", label: "Privacy" },
    { href: "/conditions", label: "Conditions" },
    { href: "/about", label: "About" },
    { href: "/career", label: "Career" },
  ]

  const closeSuspendedMessage = useCallback(() => {
    setItem("suspended_message", true)
    notification.destroy("suspended_message")
  }, [])

  useEffect(() => {
    if (!userInfo) {
      return
    }

    const shown = getItem("suspended_message")
    if (userInfo?.is_suspended) {
      const btn = (
        <Button
          type="light"
          className="px-6"
          size="small"
          onClick={closeSuspendedMessage}
        >
          Close
        </Button>
      )

      const close = () => {
        setItem("suspended_message", true)
      }

      if (!shown) {
        notification.destroy("suspended_message")
        notification.open({
          btn,
          onClose: close,
          key: "suspended_message",
          closeIcon: "",
          duration: 0,
          message: "Warning",
          description:
            "We regret to inform you that your account has been Suspended due to a violation of our terms and conditions. Please contact our support team for further information and assistance.",
        })
      }
    } else {
      removeItem("suspended_message")
    }
  }, [dispatch, userInfo, closeSuspendedMessage])

  const menuItems = useMemo(() => {
    return items.map(item => ({
      key: item.href.replaceAll("/", ""),
      label: (
        <Link
          passHref
          prefetch={false}
          href={item.href}
          target={item.target}
          className={`hover:text-primary ${
            String(router.pathname)?.includes(item.key)
              ? "text-primary"
              : "text-black"
          }`}
        >
          {item.label}
        </Link>
      ),
    }))
  }, [])

  const menuOtherItems = useMemo(() => {
    return otherItems.map(item => ({
      key: item.href.replaceAll("/", ""),
      label: (
        <Link
          passHref
          prefetch={false}
          href={item.href}
          target={item.target}
          className={`hover:text-primary ${
            String(router.pathname)?.includes(item.key)
              ? "text-primary"
              : "text-black"
          }`}
        >
          {item.label}
        </Link>
      ),
    }))
  }, [])

  return (
    <header
      className={`${styles.header} ${!isHome ? styles.shadow : ""}`}
      style={
        isHome
          ? { backgroundColor: "rgb(242, 242, 242, 0.8)" }
          : { backgroundColor: "#fff" }
      }
    >
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 20, offset: 2 }}
          xl={{ span: 18, offset: 3 }}
          xxl={{ span: 14, offset: 5 }}
        >
          <div
            className="flex justify-between items-center header-menu"
            style={{ height: 56 }}
          >
            <div className="flex-none mr-4">
              <Link passHref prefetch={false} href="/">
                <img
                  src="/assets/logo.png"
                  alt="logo"
                  className="h-6 cursor-pointer"
                />
              </Link>
            </div>
            <div className="flex-grow hidden md:block">
              <Menu
                selectedKeys={[router.pathname.replaceAll("/", "")]}
                defaultSelectedKeys={[""]}
                mode="horizontal"
                className="border-0"
                items={menuItems}
                onSelect={() => setVisibleDrawer(false)}
              />
            </div>
            <div className="flex-none hidden md:block">
              <div className="flex items-center">
                <UserBox
                  setAddCommunity={setAddCommunity}
                  setAddCompany={setAddCompany}
                />
              </div>
            </div>

            <div className="sm:block py-1 md:hidden">
              <CPButton
                type="plain"
                onClick={() => {
                  setVisibleDrawer(true)
                }}
              >
                <span className="icon-ic_menu_24px" />
              </CPButton>
            </div>

            <Drawer
              placement="left"
              closable={false}
              onClose={() => {
                setVisibleDrawer(false)
              }}
              open={visibleDrawer}
              width="80%"
              styles={{
                body: { padding: "10px 0 20px" },
                header: { padding: 0 },
              }}
            >
              <div className="flex items-center justify-around">
                <UserBox onClose={() => setVisibleDrawer(false)} />
              </div>

              <Divider className="border-gray-300 my-3" />

              <Menu
                selectedKeys={[router.pathname.replaceAll("/", "")]}
                defaultSelectedKeys={[""]}
                mode="vertical"
                className="border-0"
                items={menuItems.concat(menuOtherItems)}
                onSelect={() => setVisibleDrawer(false)}
              />

              {userInfo && (
                <ProfileMenu
                  drawer
                  className="border-0 mt-2 px-3"
                  onClose={() => setVisibleDrawer(false)}
                  setAddCommunity={setAddCommunity}
                  setAddCompany={setAddCompany}
                />
              )}
            </Drawer>
          </div>
        </Col>
      </Row>

      <ModalAddCommunity
        showModal={addCommunity}
        toggleModal={setAddCommunity}
      />
      <ModalAddCompany showModal={addCompany} toggleModal={setAddCompany} />
    </header>
  )
}

export default Header
