import SubscriptionModal from "components/Subscription/SubscriptionModal"
import { createContext, useContext } from "react"
import { notifyToast } from "services/notifyToast"
import { useModal } from "utils/hooks/useModal"

export const SubscriptionContext = createContext({})

export const useSubscriptionContext = () => {
  const context = useContext(SubscriptionContext)

  if (context === undefined) {
    throw new Error(
      "useSubscriptionContext must be used within SubscriptionProvider"
    )
  }

  return context
}

export const SubscriptionProvider = ({ children }) => {
  const {
    selectors: { modalIsOpen },
    actions: { handleModalOpening, handleModalClosing },
  } = useModal()

  const checkSubscription = error => {
    const { status, data } = error?.response || {}

    if (status === 403 && data?.errors?.messages === "Upgrade your plan") {
      handleModalOpening()

      return { forceClose: true }
    }

    notifyToast(error)
  }

  return (
    <SubscriptionContext.Provider
      value={{
        modalIsOpen,
        handleModalClosing,
        checkSubscription,
      }}
    >
      {children}

      <SubscriptionModal />
    </SubscriptionContext.Provider>
  )
}
