import { configureStore } from "@reduxjs/toolkit"
import { createWrapper } from "next-redux-wrapper"
import addPostReducer from "./slices/addPostSlice"
import areaOfStudiesReducer from "./slices/areaOfStudiesSlice"
import communityCategory from "./slices/communityCategorySlice"
import communityReducer from "./slices/communitySlice"
import companyAOEReducer from "./slices/companyAOESlice"
import companyIndustriesReducer from "./slices/companyIndustriesSlice"
import companySizesReducer from "./slices/companySizesSlice"
import companyReducer from "./slices/companySlice"
import companyTypeReducer from "./slices/companyTypeSlice"
import countryReducer from "./slices/countrySlice"
import degreeReducer from "./slices/degreeSlice"

import feedReducer from "./slices/feedSlice"
import individualAOEReducer from "./slices/individualAOESlice"
import languageReducer from "./slices/languageSlice"
import materialsReducer from "./slices/materialsSlice"
import messageReducer from "./slices/messageSlice"
import policyLists from "./slices/policyListsSlice"
import productCategoriesReducer from "./slices/productCategoriesSlice"
import productPropertiesReducer from "./slices/productPropertiesSlice"
import profileReducer from "./slices/profileReducer"
import projectFunctionsReducer from "./slices/projectFunctionsSlice"
import projectScalesReducer from "./slices/projectScalesSlice"
import projectStatusReducer from "./slices/projectStatusSlice"
import projectTypesReducer from "./slices/projectTypesSlice"
import propCompanyReducer from "./slices/propCompanySlice"
import serviceAreasReducer from "./slices/serviceAreasSlice"
import serviceCategoriesReducer from "./slices/serviceCategoriesSlice"
import skillsReducer from "./slices/skillsSlice"
import socialNetworksReducer from "./slices/socialNetworksSlice"

const reducers = {
  communityCategory,
  propCompany: propCompanyReducer,
  addPost: addPostReducer,
  policies: policyLists,
  company: companyReducer,
  community: communityReducer,
  feed: feedReducer,
  skills: skillsReducer,
  degrees: degreeReducer,
  userInfo: profileReducer,
  country: countryReducer,
  message: messageReducer,
  languages: languageReducer,
  companyAOE: companyAOEReducer,
  companyType: companyTypeReducer,
  projectTypes: projectTypesReducer,
  companySizes: companySizesReducer,
  individualAOE: individualAOEReducer,
  areaOfStudies: areaOfStudiesReducer,
  projectStatus: projectStatusReducer,
  projectScales: projectScalesReducer,
  socialNetworks: socialNetworksReducer,
  projectFunctions: projectFunctionsReducer,
  serviceCategories: serviceCategoriesReducer,
  serviceMaterials: materialsReducer,
  productProperties: productPropertiesReducer,
  companyIndustries: companyIndustriesReducer,
  productCategories: productCategoriesReducer,
  serviceAreas: serviceAreasReducer,
}

export const makeStore = (preloadedState = {}) =>
  configureStore({
    reducer: reducers,
    devTools: true,
    preloadedState,
  })

export const wrapper = createWrapper(makeStore, { debug: false })
