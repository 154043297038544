import { Skeleton } from "antd"
import RequestCommunityItem from "components/Notifications/requests/RequestCommunityItem"
import RequestCompanyItem from "components/Notifications/requests/RequestCompanyItem"
import RequestConnectItem from "components/Notifications/requests/RequestConnectItem"
import { Fragment, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useSelector } from "react-redux"
import { profileState } from "redux/slices/profileReducer"
import apiService from "services/apiService"
import { notifyToast } from "services/notifyToast"

export default function NotificationRequests(Props) {
  const { userInfo } = useSelector(profileState)

  const [page, setPage] = useState(1)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState(null)
  const isLastPage =
    !pagination || pagination?.current_page >= pagination?.last_page

  const fetchData = async () => {
    try {
      const { data } = await apiService.get(
        `/api/network/connection/requests?page=${page}`
      )

      if (data?.meta) {
        setPagination(data?.meta)
      }
      if (page === 1) {
        setItems(data?.data)
      } else {
        setItems([...items, ...data.data])
      }
    } catch (e) {
      notifyToast(e)
    }

    setLoading(false)
  }

  /**
   * fetch data
   */
  useEffect(() => {
    setLoading(true)

    fetchData()
  }, [userInfo, page])

  const renderRow = item => {
    switch (item.request_type) {
      case "individual_connection":
        return (
          <RequestConnectItem
            item={item}
            onFetch={() => {
              setPage(1)
              fetchData()
            }}
          />
        )
      case "community_member":
        return (
          <RequestCommunityItem
            item={item}
            onFetch={() => {
              setPage(1)
              fetchData()
            }}
          />
        )
      case "company_admin":
        return (
          <RequestCompanyItem
            item={item}
            onFetch={() => {
              setPage(1)
              fetchData()
            }}
          />
        )
    }
  }

  return (
    <div {...Props}>
      {loading && items?.length === 0 && (
        <Skeleton className="small-skeleton" active paragraph={{ rows: 1 }} />
      )}

      {items?.length !== 0 ? (
        <InfiniteScroll
          pageStart={0}
          useWindow={false}
          initialLoad={false}
          hasMore={!isLastPage}
          loadMore={() => !isLastPage && setPage(pagination?.current_page + 1)}
          loader={
            <Skeleton
              key={0}
              active
              className="small-skeleton"
              paragraph={{ rows: 1 }}
            />
          }
        >
          {items.map((item, index) => (
            <Fragment key={index}>{renderRow(item)}</Fragment>
          ))}
        </InfiniteScroll>
      ) : (
        !loading && (
          <div className="pb-2 text-gray-500 f-13">No new request.</div>
        )
      )}
    </div>
  )
}
