import { Skeleton } from "antd"
import CPInput from "components/CP/CPInput"
import { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch, useSelector } from "react-redux"
import {
  messageState,
  setNewChatOpened,
  setOpenedChatDetails,
} from "redux/slices/messageSlice"
import { buildSearchQuery } from "utils/global"
import useFetch from "utils/useFetch"
import ConnectionListRow from "./ConnectionListRow"

function RenderHeader() {
  const dispatch = useDispatch()
  const { isNewChatOpened } = useSelector(messageState)

  return (
    <div className=" flex items-center flex-shrink-0">
      <span
        className="icon-ic_arrow_back_24px cursor-pointer"
        onClick={() => dispatch(setNewChatOpened(!isNewChatOpened))}
      />
      <div className="flex-1 flex items-center">
        <div className="font-medium ml-3.5 flex-1">New Message</div>
      </div>
    </div>
  )
}

const Loading = () => {
  return (
    <div className="px-4">
      <Skeleton active paragraph={{ rows: 4 }} className="small-skeleton" />
    </div>
  )
}

export default function NewMessageList() {
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)
  const [searchFilters, setSearchFilters] = useState(null)
  const [fetchPagination, setFetchPagination] = useState(null)
  const [loadingData, fetchedData, { error }] = useFetch(
    `/api/network/connection/?page=${page}${buildSearchQuery(searchFilters)}`
  )

  useEffect(() => {
    if (fetchedData?.data?.length > 0) {
      setFetchPagination([...(fetchPagination || []), ...fetchedData.data])
    }
  }, [fetchedData])

  const isLastPage =
    fetchedData?.meta?.current_page >= fetchedData?.meta?.last_page

  function handleFilter(params) {
    setSearchFilters(params)
    setFetchPagination(null)
    setPage(1)
  }

  function setFilter(params) {
    const final = { ...searchFilters, ...params }
    handleFilter(final)
  }

  function removeFilter(nameArray) {
    const final = { ...searchFilters }
    nameArray?.forEach(element => {
      delete final[element]
    })
    handleFilter(final)
  }

  return (
    <div style={{ height: 375 }} className="flex flex-col">
      <div className="px-3 pt-3 pb-2">
        <RenderHeader />

        <CPInput
          placeholder="Search"
          className="mt-4"
          prefix={<span className="icon-ic_search_24px" />}
          allowClear
          onChange={e => {
            e?.target?.value
              ? setFilter({ search_q: e?.target?.value })
              : removeFilter(["search_q"])
          }}
        />
      </div>

      <div className="flex-1 overflow-y-auto overflow-x-hidden">
        <InfiniteScroll
          pageStart={0}
          useWindow={false}
          initialLoad={false}
          loadMore={() =>
            !isLastPage && setPage(fetchedData?.meta?.current_page + 1)
          }
          hasMore={!isLastPage}
        >
          <>
            {fetchPagination?.length > 0 &&
              fetchPagination?.map((item, index) => (
                <ConnectionListRow
                  key={index}
                  img={item?.profile_photo}
                  title={`${item?.first_name} ${item?.last_name}`}
                  onClick={() => dispatch(setOpenedChatDetails(item))}
                />
              ))}

            {!loadingData && !fetchPagination?.length && (
              <div className="my-4 text-center text-gray-500 f-12">
                No result found!
              </div>
            )}
          </>

          {!error && loadingData && <Loading />}
        </InfiniteScroll>
      </div>
    </div>
  )
}
