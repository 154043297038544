import { Badge } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { profileState, setProfile } from "redux/slices/profileReducer"
import { truncate } from "utils/global"

export default function ConnectionListRow({
  img,
  newMessages = 0,
  onClick = null,
  title = "Message",
  subtitle = "Click to view details",
}) {
  const dispatch = useDispatch()
  const { userInfo } = useSelector(profileState)

  return (
    <div
      className="flex items-center flex-shrink-0 py-2 px-4 cursor-pointer"
      onClick={() => {
        dispatch(
          setProfile({
            ...userInfo,
            message_badge:
              userInfo?.message_badge > newMessages
                ? userInfo?.message_badge - newMessages
                : 0,
          })
        )
        onClick()
      }}
    >
      <div
        className="w-8 h-8 min-w-8 rounded-full border border-solid border-gray-600 bg-cover cursor-pointer bg-center mr-3.5 flex-shrink-0"
        style={{
          backgroundImage: `url(${
            img || "/assets/onBoarding/profile_avatar.jpg"
          })`,
        }}
      />
      <div className={newMessages ? "w-48" : "w-52"}>
        <div
          className={`${
            newMessages ? "font-bold" : ""
          } text-xs max-w-220 truncate f-13`}
        >
          {title}
        </div>
        <div className="text-xs f-11 text-gray-500 max-w-220 truncate break-words">
          {truncate(subtitle, {
            length: 35,
            word: false,
          })}
        </div>
      </div>
      {newMessages > 0 && (
        <Badge overflowCount={10} size="small" count={newMessages} />
      )}
    </div>
  )
}
