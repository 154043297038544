import { Button, Divider } from "antd"
import { CPModal } from "components/CP/CPModal"
import { useRouter } from "next/router"
import { useSubscriptionContext } from "./SubscriptionProvider"

export default function SubscriptionModal() {
  const router = useRouter()
  const { modalIsOpen, handleModalClosing } = useSubscriptionContext()

  return (
    <CPModal
      centered
      footer={false}
      destroyOnClose
      open={modalIsOpen}
      onCancel={handleModalClosing}
      title="Upgrade Notice"
      className="rounded-lg p-0"
    >
      <div className="p-5">
        <div className="px-1 mb-5">
          Before continuing, you have to upgrade your plan to do this action!
        </div>

        <Divider className="border-gray-300 border-t-1 m-0" />

        <div className="pt-4 flex justify-end">
          <Button type="light" className="px-8" onClick={handleModalClosing}>
            Close
          </Button>
          <Button
            type="primary"
            className="px-8"
            onClick={() => {
              handleModalClosing()
              router.push("/subscription")
            }}
          >
            See Plans
          </Button>
        </div>
      </div>
    </CPModal>
  )
}
