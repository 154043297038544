import { Skeleton } from "antd"
import ChatDetailsRow from "components/Messaging/ChatDetailsRow"
import _ from "lodash"
import moment from "moment"
import Pusher from "pusher-js"
import { Fragment, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useSelector } from "react-redux"
import { messageState } from "redux/slices/messageSlice"
import { profileState } from "redux/slices/profileReducer"
import { getItem } from "utils/storage"
import useFetch from "utils/useFetch"

const connectToPusher = (roomId, onNewMessage) => {
  const accessToken = getItem("access_token")
  if (!accessToken) {
    return
  }

  const pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER, {
    cluster: "mt1",
    authEndpoint: `${process.env.NEXT_PUBLIC_BASE_URL}/api/auth/pusher`,
    auth: {
      headers: {
        authorization: `Bearer ${accessToken?.replace(/"/g, "")}`,
        room: roomId,
      },
    },
  })

  const channelName = `private-chat${roomId}`
  const channel = pusher.subscribe(channelName)
  channel.bind("new_message", data => {
    if (onNewMessage) {
      onNewMessage(data)
    }
    return data
  })
}

const Loading = () => {
  return <Skeleton className="small-skeleton" active paragraph={{ rows: 4 }} />
}

export default function ChatDetails({ newData }) {
  const [page, setPage] = useState(1)
  const { userInfo } = useSelector(profileState)
  const { openedChatDetails } = useSelector(messageState)

  const [loadingData, fetchedData, { error }] = useFetch(
    `/api/message/room/${openedChatDetails?.room_id}/messages?page=${page}`
  )
  const [fetchPagination, setFetchPagination] = useState(null)
  const [isLastPage, setIsLastPage] = useState(true)
  const normalizeDataAndSet = newData => {
    setFetchPagination(prev => {
      const oldData = _.flatMap(prev, s => s)
      const array = [...(oldData || []), ...newData]
      const groupByDay = item =>
        moment(item.created_at, "YYYY-MM-DD").format("MMM DD YYYY")
      const result = _.mapValues(_.groupBy(array, groupByDay), v =>
        _.sortBy(v, "created_at")
      )

      const ordered = {}
      _(result)
        .keys()
        .orderBy(vv => new Date(vv), "asc")
        .each(function (key) {
          ordered[key] = result[key]
        })

      return ordered
    })
  }
  const onNewMessage = data => {
    if (data?.sender_user_id === userInfo?.user_id) {
      return
    }

    normalizeDataAndSet([data])
    setTimeout(() => {
      const container = document.getElementById("chat-container")
      // scroll to bottom on receive new msg
      if (container) {
        container.scrollTop = container?.scrollHeight
      }
    }, 150)
  }

  useEffect(() => {
    if (newData) {
      onNewMessage(newData)
    }
  }, [newData])
  useEffect(() => {
    if (fetchedData?.data?.length > 0) {
      normalizeDataAndSet(fetchedData?.data)

      const container = document.getElementById("chat-container")
      setTimeout(() => {
        if (container) {
          if (!fetchPagination) {
            // on first time -> scroll to bottom
            if (container) {
              container.scrollTop = container?.scrollHeight
            }
          } else if (container) {
            container.scrollTop = container?.clientHeight / 2
          }
        }
      }, 200)

      setTimeout(() => {
        setIsLastPage(
          fetchedData?.meta?.current_page >= fetchedData?.meta?.last_page
        )
        if (!fetchPagination) {
          if (container) {
            container.scrollTop = container?.scrollHeight
          }
        }
      }, 400)
    }
  }, [fetchedData])
  useEffect(() => {
    if (!openedChatDetails?.room_id) {
      return
    }
    connectToPusher(openedChatDetails?.room_id, onNewMessage)
  }, [openedChatDetails])

  return (
    <div
      id="chat-container"
      className="flex-1 overflow-y-auto overflow-x-hidden px-5"
    >
      <InfiniteScroll
        isReverse
        pageStart={1}
        threshold={40}
        useWindow={false}
        initialLoad={false}
        loadMore={() =>
          !isLastPage && setPage(fetchedData?.meta?.current_page + 1)
        }
        hasMore={!loadingData && !isLastPage}
        loader={!error && <Loading key={0} />}
      >
        <>
          {fetchPagination &&
            Object.entries(fetchPagination)?.map((item, index) => (
              <Fragment key={index}>
                <div className="flex items-center h-full">
                  <div className="border-b border-gray-300 w-1/3" />
                  <div className="text-center text-gray-500 mx-4 flex-shrink-0 text-xs font-normal">
                    {item[0]}
                  </div>
                  <div className="border-b border-gray-300 w-1/3" />
                </div>
                <div>
                  {item[1]?.map((chat, j) => (
                    <ChatDetailsRow
                      key={`ChatDetailsRow-${index}-${j}`}
                      item={chat}
                    />
                  ))}
                </div>
              </Fragment>
            ))}

          {!loadingData && !fetchPagination && (
            <div className="my-4 text-center f-13 text-gray-500">
              No chat history found!
            </div>
          )}
        </>
      </InfiniteScroll>
    </div>
  )
}
