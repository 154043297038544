import { CPModal } from "components/CP/CPModal"
import CountryAndCitySelector from "components/CustomFields/CountryAndCitySelector"
import FormGenerator from "components/FormGenerator"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import { useRouter } from "next/router"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { getProfile } from "redux/slices/profileReducer"
import apiService from "services/apiService"

const formRules = {
  company_name: {
    required: "company name is required",
  },
  headline: {
    required: "headline is required",
  },
  city_id: {
    required: "city is required",
  },
  country_id: {
    required: "country is required",
  },
}

const formFields = [
  {
    key: "company_name",
    title: "Company Name",
    fieldType: "input",
    col: {
      xs: 24,
    },
    placeholder: false,
  },
  {
    key: "headline",
    title: "Headline",
    fieldType: "input",
    col: {
      xs: 24,
    },
    placeholder: false,
  },
  {
    key: "city_id",
    title: "Location",
    fieldType: "customSingleField",
    col: {
      xs: 24,
    },
    placeholder: false,
    render: formProps => {
      const { register, setValue, watch } = formProps
      register("city_id")
      register("country_id")

      const country = watch("country_id")
      const city = watch("city_id")

      return (
        <CountryAndCitySelector
          rules={formRules}
          initialData={{
            country_id: country,
            city_id: city,
          }}
          onChange={data => {
            setValue("city_id", data?.city_id)
            setValue("country_id", data?.country_id)
          }}
        />
      )
    },
  },
  {
    key: "postal_code",
    title: "Postal Code",
    fieldType: "input",
    col: {
      xs: 24,
    },
    placeholder: false,
  },
]

export default function ModalAddCompany({ showModal, toggleModal }) {
  const router = useRouter()
  const dispatch = useDispatch()
  const { checkSubscription } = useSubscriptionContext()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async values => {
    try {
      setLoading(true)
      values.area_of_expertises = []

      const res = await apiService.post("/api/company/profile/", values)
      if (res.status === 200) {
        toggleModal(false)
        await dispatch(getProfile())

        return router.push(
          `/company/${res?.data?.data?.username}?type=show_admins`
        )
      }
    } catch (e) {
      checkSubscription(e)
      setLoading(false)
    }
  }

  return (
    <CPModal
      className="rounded-lg p-0"
      title="Build Company Profile"
      footer={null}
      centered
      width={500}
      destroyOnClose
      open={showModal}
      onCancel={() => toggleModal(false)}
    >
      <div className="p-5">
        <FormGenerator
          loading={loading}
          rules={formRules}
          fields={formFields}
          onSubmit={handleSubmit}
          submitText="Build Profile"
        />
      </div>
    </CPModal>
  )
}
