import { Avatar, Divider } from "antd"
import Link from "next/link"
import { useSelector } from "react-redux"
import { profileState } from "redux/slices/profileReducer"

export default function ProfileMenuCompanyList({ overflow = true, onClose }) {
  const { userInfo } = useSelector(profileState)

  return (
    userInfo?.companies?.length > 0 && (
      <>
        <Divider className="border-gray-300 my-0" />

        <div className="font-bold pt-2 pb-1 f-16">Companies</div>

        <div
          style={
            overflow
              ? { maxHeight: "122px", overflow: "auto" }
              : { padding: "5px 0" }
          }
        >
          {userInfo?.companies?.map((item, index) => (
            <Link
              passHref
              prefetch={false}
              key={index}
              href={`/company/${item?.username}`}
            >
              <div
                className="pe-2 flex items-center cursor-pointer py-1"
                onClick={onClose}
              >
                <Avatar
                  size={30}
                  alt={item?.company_name}
                  src={
                    item?.main_profile_picture_filename ||
                    "/assets/onBoarding/profile_avatar.jpg"
                  }
                  className="mr-2 bg-gray-100"
                />
                <div className="flex flex-col">
                  <div
                    className="f-14 text-gray-900 truncate"
                    style={{ maxWidth: 140 }}
                  >
                    {item?.company_name}
                  </div>
                  <span className="f-12 text-gray-500">Company Profile</span>
                </div>
                <div className="flex-grow" />
                <span className="icon-ic_keyboard_arrow_right_24px text-gray-800" />
              </div>
            </Link>
          ))}
        </div>
      </>
    )
  )
}
